import '@/shared/styles/external-styles';

import * as React from 'react';

import { withProviders } from '@/app/providers';
import { YandexMetric } from '@/app/providers/yandex-metrica';

import { NetworkToast } from '@/features/network-toast';

import { Toaster } from '@/shared/components/ui/sonner';
import { PageSeo } from '@/shared/lib/seo';
import { EnhancedAppProps } from '@/shared/types/enhanced-app-props';

function App(props: EnhancedAppProps) {
  const { Component, pageProps } = props;

  const Layout = Component.Layout ?? React.Fragment;

  return (
    <>
      <YandexMetric />
      <PageSeo />

      <Layout>
        <Component {...pageProps} />

        <NetworkToast />
      </Layout>
      <Toaster />
    </>
  );
}

export default withProviders(App);
