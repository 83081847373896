import React from 'react';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';

const YM_ID = 96886492;

// eslint-disable-next-line react/display-name
export const YandexMetric = () => {
  const router = useRouter();

  React.useEffect(() => {
    window.ym(YM_ID, 'hit', window.location.href);
  }, [router.pathname, router.query]);

  return (
    <>
      <noscript>
        <div>
          <img src="https://mc.yandex.ru/watch/96886492" style={{ position: 'absolute', left: -9999 }} alt="" />
        </div>
      </noscript>
      <Script strategy="afterInteractive" id="yandex-metrika">
        {`
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(${YM_ID}, "init", {
            defer: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true
        });
      `}
      </Script>
    </>
  );
};
