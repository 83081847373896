import { AppProps } from 'next/app';
import { EffectorNext, getClientScope } from '@effector/next';
import { attachReduxDevTools } from '@effector/redux-devtools-adapter';

import { env } from '@/shared/config/env.mjs';
import { EFFECTOR_STATE_KEY, EffectorState } from '@/shared/lib/effector';
import { AttachRouter } from '@/shared/lib/effector/router';
import { APP_TITLE } from '@/shared/lib/seo/meta';

type AppWithEffectorState = AppProps & EffectorState;

// const clientScope = getClientScope();

// if (clientScope && env.NODE_ENV === 'development') {
//   /**
//    * Notice, that we need to check for the client scope first
//    *
//    * It will be `null` at the server
//    */
//   attachReduxDevTools({
//     scope: clientScope,
//     name: APP_TITLE,
//     trace: true,
//   });
// }

// eslint-disable-next-line react/display-name
export const withEffector = (Component: AppWithEffectorState['Component']) => (props: AppWithEffectorState) => {
  const { pageProps } = props;

  const values = pageProps[EFFECTOR_STATE_KEY];

  return (
    <EffectorNext values={values}>
      <AttachRouter />
      <Component {...props} />
    </EffectorNext>
  );
};
