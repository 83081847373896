import { env } from '@/shared/config/env.mjs';

export const APP_TITLE = 'Дом Станков';
export const APP_DESCRIPTION = `Дом станков - ваш надежный партнер в сфере станочного оборудования. Мы специализируемся на поставке высококачественных
станков для обработки дерева и металла, предлагая широкий ассортимент оборудования от ведущих
производителей. Наш опыт и профессионализм позволяют нам предложить клиентам оптимальные решения для любых
задач по обработке материалов. Мы гарантируем надежность, качество и индивидуальный подход к каждому
клиенту, помогая им повысить эффективность производства и достичь высоких результатов. Доверьтесь нам, и
ваш бизнес получит надежную поддержку в области станочного оборудования`;

interface GetAlternateHrefLinksProps {
  asPath: string;
  locales?: string[];
}

export const getAlternateHrefLinks = (props: GetAlternateHrefLinksProps) => {
  const { asPath, locales = [] } = props;

  if (!env.NEXT_PUBLIC_APP_URL) {
    return [];
  }

  return locales
    .concat('x-default')
    .filter((locale: string) => locale !== 'cimode')
    .map((locale: string) => {
      const localePath = locale === 'x-default' ? '' : `/${locale}`;
      const href = `${env.NEXT_PUBLIC_APP_URL}${localePath}${asPath}`;

      return {
        hrefLang: locale,
        href,
      };
    });
};
